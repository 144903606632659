import { QuickOnboardingNoContentPage } from '@komi-app/creator-ui';
import selfless from '@komi-app/selfless';
import When from '@komi-app/when';
import {
  ActionFunction,
  json,
  LoaderFunction,
  redirect,
  useActionData,
  useNavigate,
  useNavigation,
  useSubmit,
} from 'react-router-dom';
import { getOnboardingSDK, useQuickOnboardingFlag } from '../../sdks';
import useLogRocket from '../../hooks/logrocket';
import useToastData from '../../hooks/toast-data';
import useOnboardingData, {
  getOnboardingData,
  isValidTemplate,
} from '../../hooks/onboarding-data';
import ProfilePreview, {
  PreviewProfile,
} from '../../components/ProfilePreview/ProfilePreview';
import { FLAGS, useFeatureIsOn } from '@komi-app/flags-sdk';
import { tracking } from '../../sdks';
import { ScraperCalledFromPage } from '@komi-app/onboarding-sdk';

interface MoreInfoViewProps {
  onNext: (linkInBio: string) => void;
  onNoLinkInBio: () => void;
  loading: boolean;
  hasPreview: boolean;
  profile: PreviewProfile;
}

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Onboarding';

  const isQuickOnboardingOn = await useQuickOnboardingFlag();

  if (!isQuickOnboardingOn) {
    return redirect('/signin');
  }

  const data = getOnboardingData();

  if (!data) {
    return redirect('/signin');
  }

  return json({
    success: true,
  });
};

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  const link = formData.get('link') as string;
  const template = formData.get('template') as string;

  try {
    if (!template || !isValidTemplate(template)) {
      throw new Error('Invalid template');
    }

    const onboarding = getOnboardingSDK();
    const preview = await onboarding.createPreview({
      inputs: [
        {
          platform: 'link_in_bio',
          data: link,
        },
      ],
      template,
      calledFromPage: ScraperCalledFromPage.LINKINBIO,
    });

    if (!preview) {
      throw new Error('Failed to create preview');
    }

    return { preview };
  } catch (e: any) {
    console.error(e);

    return redirect(`/onboarding/social-links?template=${template}`);
  }
};

export const useMoreInfoPage = (): MoreInfoViewProps => {
  useLogRocket();
  useToastData();

  const data = useOnboardingData();
  const useAnalyticsSDK = useFeatureIsOn(FLAGS.USE_ANALYTICS_SDK_AUTH);

  const submit = useSubmit();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const isSubmitting = navigation.state === 'submitting';
  const isRedirecting =
    navigation.state === 'loading' &&
    navigation.formData != null &&
    navigation.formAction !== navigation.location.pathname;

  const previewData = useActionData() as { preview: PreviewProfile };

  const handleNext = (link: string) => {
    if (!data) return;

    if (useAnalyticsSDK) {
      tracking.trackQuickOnboardingLinkInBioNextClick({
        websiteUrl: link,
      });
    }

    submit({ link, template: data.template }, { method: 'POST' });
  };

  const handleNoLinkInBio = () => {
    navigate(`/onboarding/social-links?template=${data?.template}`);
  };

  return {
    onNext: handleNext,
    onNoLinkInBio: handleNoLinkInBio,
    loading: isSubmitting || isRedirecting,
    hasPreview: !!previewData?.preview?.previewId,
    profile: previewData?.preview,
  };
};

export const MoreInfoView = ({
  onNext,
  onNoLinkInBio,
  loading,
  hasPreview,
  profile,
}: MoreInfoViewProps) => {
  return (
    <When
      value={hasPreview}
      then={<ProfilePreview profile={profile} />}
      otherwise={
        <QuickOnboardingNoContentPage
          onNext={onNext}
          onNoLinkInBio={onNoLinkInBio}
          loading={loading}
        />
      }
    />
  );
};

export const MoreInfo = selfless(MoreInfoView, useMoreInfoPage);
export default MoreInfo;

export const route = {
  path: '/onboarding/more-info',
  element: <MoreInfo />,
  loader,
  action,
};
