import {
  QuickOnboardingSocialLinksPage,
  SocialLinkResult,
} from '@komi-app/creator-ui';
import When from '@komi-app/when';
import selfless from '@komi-app/selfless';
import {
  ActionFunction,
  json,
  LoaderFunction,
  redirect,
  useActionData,
  useNavigation,
  useSubmit,
} from 'react-router-dom';
import { getOnboardingSDK, useQuickOnboardingFlag } from '../../sdks';
import useLogRocket from '../../hooks/logrocket';
import useToastData from '../../hooks/toast-data';
import useOnboardingData, {
  getOnboardingData,
  isValidTemplate,
} from '../../hooks/onboarding-data';
import {
  ScrapePlatform,
  ScraperCalledFromPage,
} from '@komi-app/onboarding-sdk';
import ProfilePreview from '../../components/ProfilePreview';
import { PreviewProfile } from '../../components/ProfilePreview/ProfilePreview';
import { FLAGS, useFeatureIsOn } from '@komi-app/flags-sdk';
import { tracking } from '../../sdks';
import { ProfileLinkTypes } from '@komi-app/meta-profiles';

interface SocialLinksViewProps {
  onNext: (links: SocialLinkResult[]) => void;
  loading: boolean;
  profile: PreviewProfile;
  hasPreview: boolean;
}

export const loader: LoaderFunction = async () => {
  document.title = 'KOMI | Onboarding';

  const isQuickOnboardingOn = await useQuickOnboardingFlag();

  if (!isQuickOnboardingOn) {
    return redirect('/signin');
  }

  const data = getOnboardingData();

  if (!data) {
    return redirect('/signin');
  }

  return json({
    success: true,
  });
};

export const action: ActionFunction = async ({ request }) => {
  try {
    const formData = await request.formData();
    const links = JSON.parse(formData.get('links') as string) as {
      platform: ScrapePlatform;
      data: string;
    }[];

    const template = formData.get('template') as string;

    if (!template || !isValidTemplate(template)) {
      throw new Error('Invalid template');
    }

    const onboarding = getOnboardingSDK();
    const preview = (await onboarding.createPreview({
      inputs: links,
      template,
      calledFromPage: ScraperCalledFromPage.SOCIALLINKS,
    })) as unknown as PreviewProfile;

    if (!preview) {
      throw new Error('Failed to create preview');
    }
    if (!preview.modules.length) {
      //If no modules generated from links provided, redirect to sign up directly and carry over the previewId (legacy onboarding flow)
      return redirect(`/signup?preview_id=${preview.previewId}`);
    }
    return { preview };
  } catch (e: any) {
    return {
      toast: {
        text: 'Something went wrong',
        semantic: 'error',
        durationMs: 5000,
      },
    };
  }
};

export const useSocialLinksPage = (): SocialLinksViewProps => {
  useLogRocket();
  useToastData();

  const data = useOnboardingData();
  const useAnalyticsSDK = useFeatureIsOn(FLAGS.USE_ANALYTICS_SDK_AUTH);

  const submit = useSubmit();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';
  const isRedirecting =
    navigation.state === 'loading' &&
    navigation.formData != null &&
    navigation.formAction !== navigation.location.pathname;

  const previewData = useActionData() as { preview: PreviewProfile };

  const handleNext = (links: SocialLinkResult[]) => {
    if (!data) return;

    const validLinks = links
      .map((link) => {
        const type = //Links to scrape
          (Object.values(ScrapePlatform.Values) as string[]).includes(
            link.type.toLowerCase()
          )
            ? (link.type.toLowerCase() as ScrapePlatform)
            : //Additional social links
            (Object.values(ProfileLinkTypes) as string[]).includes(
                link.type.toUpperCase()
              )
            ? (link.type.toUpperCase() as ProfileLinkTypes)
            : [];
        return { platform: type, data: link.value };
      })
      .filter((link) => link !== null);

    submit(
      { links: JSON.stringify(validLinks), template: data.template },
      { method: 'POST' }
    );

    if (useAnalyticsSDK) {
      const query = new URLSearchParams(window.location.search);

      const instagramHandle = query.get('instagramHandle');
      const templateName = query.get('templateName');
      const websiteUrl = query.get('websiteUrl');
      const categoryName = query.get('categoryName');

      tracking.trackQuickOnboardingAddMissingLinksNextClick({
        instagramHandle,
        templateName,
        websiteUrl,
        categoryName,
        numberOfAdditionalLinks: validLinks.length,
      });
    }
  };

  return {
    onNext: handleNext,
    loading: isSubmitting || isRedirecting,
    profile: previewData?.preview,
    hasPreview: !!previewData?.preview?.previewId,
  };
};

export const SocialLinksView = ({
  onNext,
  loading,
  profile,
  hasPreview,
}: SocialLinksViewProps) => {
  return (
    <When
      value={hasPreview}
      then={<ProfilePreview profile={profile} />}
      otherwise={
        <QuickOnboardingSocialLinksPage onNext={onNext} loading={loading} />
      }
    />
  );
};

export const SocialLinks = selfless(SocialLinksView, useSocialLinksPage);
export default SocialLinks;

export const route = {
  path: '/onboarding/social-links',
  element: <SocialLinks />,
  loader,
  action,
};
